import React, {useContext} from 'react'
import  { ContentContext } from '@components/layouts'
import MarkdownPreview from '@uiw/react-markdown-preview'


const TermsOfUseDescription = () => {
    const { translate } = useContext(ContentContext)
  return (
    <>
      <section className="wrapper" 
      style={{
        backgroundImage: "url('/bg22.png')",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50%',
        backgroundSize: '100%',
      }}
      >
        <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center"
        >
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="post-header">
                <h1 className="display-1 mb-5">
                  {translate('terms_of_use')}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container pb-14 pb-md-16">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="blog single mt-n17">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <span>
                      <MarkdownPreview
                          style={{
                                  fontFamily: 'inherit',
                                  color: 'inherit',
                                  fontSize: '0.85rem',
                                  textAlign: 'justify'
                                }}
                        source={translate('conditions')}
                        warpperElement={{
                          'data-color-mode': 'light'
                        }}
                    />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TermsOfUseDescription