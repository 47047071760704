import React from 'react'
import Nav from '@components/nav'
import Footer from '@components/footer'
import TermsOfUseDescription from './TermsOfUseDescription'
import  Layout  from '../../components/layouts'

const TermsOfUse = () => {
  return (
    <Layout>
        <>
            <Nav />
            <TermsOfUseDescription />
            <Footer />
        </>
    </Layout>
  )
}

export default TermsOfUse
